import React, { useEffect } from "react"
import { SubTitleSection, Text, BlogContainer } from "../atomics"
import Layout from "../layout"
import SEO from "../seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { GiStopwatch } from "react-icons/gi"

import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { format, parseISO } from "date-fns"
import { URL_INSTA_FLYNOW, URL_YOUTUBE } from "../../constants"
import { calcReadingTime, markdownToText } from "../../utils/string"
import { FaInstagram, FaYoutube } from "react-icons/fa"
import { MdMail } from "react-icons/md"

function News({ pageContext: newsletter }) {
  useEffect(() => {
    trackCustomEvent({
      category: "View",
      action: "View",
      label: "View News",
      value: newsletter.title,
    })
  }, [newsletter])

  return (
    <Layout>
      <SEO
        pageId="news"
        title={newsletter.title}
        description={markdownToText(newsletter.content).slice(0,512)}
        meta={[
          {
            name: "type",
            content: "article",
          },
          {
            name: "date",
            content: newsletter.date,
          },
          {
            name: "url",
            content: "https://appflynow.com/newsletter/" + newsletter.url,
          },
          {
            property: "og:type",
            content: "article",
          },
          {
            property: "og:date",
            content: newsletter.date,
          },
          {
            property: "og:author",
            content: newsletter.author,
          },
          {
            property: "og:url",
            content: "https://appflynow.com/newsletter/" + newsletter.url,
          },
        ]}
      />
      <BlogContainer>
        <br />
        <SubTitleSection className="title-post">{newsletter.title}</SubTitleSection>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "Roboto",
            marginTop: 10,
          }}
        >
          <h6>Por Thalia Martins</h6>
          <h6>{format(parseISO(newsletter.date), "dd/MM/yyyy")}</h6>
        </div>
        <div
          style={{
            display: "flex",
            fontFamily: "Roboto",
            marginTop: 6,
            alignItems: "center",
          }}
        >
          <GiStopwatch size={20} />
          <h6 style={{ margin: 2, marginTop: 2 }}>
            {calcReadingTime(newsletter.content)} min
          </h6>
        </div>
        <br />

        <ReactMarkdown
          children={newsletter.content}
          className="post-content"
          rehypePlugins={[rehypeRaw]}
        />

        <br />
        <Text className="text-description" style={{ fontSize: "1rem" }}>
          <strong style={{ marginBottom: 20 }}>
            {" "}
            Gostou do nosso conteúdo? <br /> 
          </strong>
          <MdMail /> Cadastre-se gratuitamente em nossa 
          <Link to="/newsletter" target="_blank" rel="noopener noreferrer">
            {" "}
            <b>
            NEWSLETTER.
            </b>
          </Link>
          <br />
          <FaYoutube /> Inscreva-se em nosso {" "}
          <a href={URL_YOUTUBE} target="_blank" rel="noopener noreferrer">
          <b>
            CANAL do YOUTUBE.
          </b>
          </a>
            <br/>
          <FaInstagram /> Siga nossa página no {" "}
          <a href={URL_INSTA_FLYNOW} target="_blank" rel="noopener noreferrer">
            <b>
            INSTAGRAM.
            </b>
          </a>
        </Text>
        <br />
      </BlogContainer>
    </Layout>
  )
}

export default News
